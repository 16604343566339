(function($, ServiceBus, Topics) {
  if (!Topics.events
    || !Topics.events.RAMP_SIGN_UP_SUBMITTED
    || !Topics.events.RAMP_LOG_IN_SUBMITTED
    || !Topics.events.FORGOT_PASSWORD_LINK_CLICK) {
    return;
  }

  ServiceBus.on(
    Topics.events.RAMP_SIGN_UP_SUBMITTED,
    function(payload) {
      if (payload) {
        if (ServiceBus.log) {
          ServiceBus.log({
            message: 'pg_'.concat(Topics.events.RAMP_SIGN_UP_SUBMITTED),
            payload: { context: payload }
          });
        }
        $(document).trigger('perlgem.ramp.signup', payload);
      }
    }
  );

  ServiceBus.on(
    Topics.events.RAMP_LOG_IN_SUBMITTED,
    function(payload) {
      if (payload) {
        if (ServiceBus.log) {
          ServiceBus.log({
            message: 'pg_'.concat(Topics.events.RAMP_LOG_IN_SUBMITTED),
            payload: { context: payload }
          });
        }
        $(document).trigger('perlgem.ramp.login', payload);
      }
    }
  );

  ServiceBus.on(
    Topics.events.FORGOT_PASSWORD_LINK_CLICK,
    function() {
      if (ServiceBus.log) {
        ServiceBus.log({ message: 'pg_'.concat(Topics.events.FORGOT_PASSWORD_LINK_CLICK) });
      }
      $(document).trigger('perlgem.ramp.forgotPassword');
    }
  );
})(
  jQuery,
  window.GlobalServiceBus || {},
  window.ServiceBusTopics || {}
);
